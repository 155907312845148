import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
window.axios = require('axios');
require('axios');

const app = createApp(App).use(router);

app.mount('#app')
