<template>
<div class="wf-section">
    <div class="div-block-261">
        <div class="div-block-787"><img src="@/assets/images/Sylo-logo-WHT.svg" loading="lazy" width="66" alt="" class="image-34"></div>
    </div>
</div>
<div class="wf-section">
    <div class="div-block-263">
        <div class="div-block-264">
            <div class="text-block-85">Returning Customer?<a href="https://app.sylo.ai" class="link-9"> Click Here</a></div>
        </div>
        <div class="div-block-265">
            <div class="div-block-266">
                <div>
                    <h1 class="heading-3">{{heading}}</h1>
                    <div class="text-block-89">Supercharge your advertising and make more money.</div>
                    <div class="summary-mobile">
                        <div class="div-block-269 _2">
                            <div class="text-block-88 _2">Total Due Now</div>
                            <div class="text-block-88 _2" v-if="trialDays==7">$0</div>
                            <div class="text-block-88 _2" v-else>${{formatPrice(planPrice)}}</div>
                        </div>
                        <div class="div-block-269 _2">
                        <div>
                            <div class="text-block-88 _2 after"><strong>Total Due After Trial</strong></div>
                        </div>
                        <div class="div-block-958">
                            <div class="text-block-88 _2 after"><strong>${{this.planPrice}}/mo</strong></div>
                            <div class="text-block-139" style="line-height: 20px;">*plus <strong>$.25</strong> per match</div>
                        </div>
                    </div>
                    </div>
                    <div class="w-form">
                        <form id="email-form-2" name="email-form-2" data-name="Email Form 2" @submit.prevent="getFormValues">
                            <div class="div-block-854">
                                <div class="div-block-209"><label for="name" class="field-label-4">First Name <span class="text-span-11">*</span></label><input type="text" class="form-text-field w-input" v-model="userData.first_name" maxlength="256" name="first_name" data-name="Name" placeholder="First Name" required></div>
                                <div class="div-block-209"><label for="email" class="field-label-4">Last Name <span class="text-span-11">*</span></label><input type="text" class="form-text-field w-input" v-model="userData.last_name" maxlength="256" name="last_name" data-name="Email" placeholder="Last Name" required></div>
                            </div>
                            <div class="div-block-854">
                                <div class="div-block-209"><label for="name-2" class="field-label-4">Email Address <span class="text-span-11">*</span></label><input type="email" v-model="userData.email" class="form-text-field w-input" maxlength="256" name="email" data-name="Name 2" placeholder="Email" readonly required></div>
                                <div class="div-block-209"><label for="email-2" class="field-label-4">Phone Number <span class="text-span-11">*</span></label><input type="tel" v-model="userData.phone" class="form-text-field w-input" maxlength="256" name="phone" data-name="phone" placeholder="Phone" required></div>
                            </div>
                            <div class="div-block-854">
                                <div class="div-block-209"><label for="name-3" class="field-label-4">Password <span class="text-span-11">*</span></label><input type="password" v-model="userData.password" class="form-text-field w-input" maxlength="256" name="name-2" data-name="Name 2" placeholder="Password" required></div>
                                <div class="div-block-209"><label for="email-3" class="field-label-4">Re-Enter Password<span class="text-span-11">*</span></label><input type="password" v-model="userData.confirm_password" class="form-text-field w-input" maxlength="256" name="email-2" data-name="Email 2" placeholder="Confirm Password" required></div>
                            </div>
                            <input type="hidden" name="xxTrustedFormCertUrl" id="xxTrustedFormCertUrl_0" value="">
                            <input id="leadid_token" name="universal_leadid" type="hidden" value="" />
                            <div class="div-block-268"><input type="text" v-bind:class="{ 'couponerror': couponErr}" class="text-field-5 w-input" v-model="coupon" maxlength="256" name="field" data-name="Field" placeholder="Coupon Code" id="field">
                                <a href="#" class="button-15 w-button" @click="validateCoupon($event)">Apply</a>
                            </div>
                            <div class="div-block-853">
                                <div class="text-block-80"><strong>Credit Card</strong></div><img src="@/assets/images/visa.png" loading="lazy" width="35" alt="" class="image-4"><img src="@/assets/images/mastercard.png" loading="lazy" width="35" alt="" class="image-4"><img src="@/assets/images/AmEx.png" loading="lazy" width="35" alt="" class="image-4"><img src="@/assets/images/discover.png" loading="lazy" width="35" alt="" class="image-4">
                            </div>
                            <div>
                                <div ref="card" class="w-input" style="padding-top: 1em;"></div>
                            </div>
                            <div class="div-block-854">
                            </div>
                            <div>
                                <input type="hidden" id="leadid_tcpa_disclosure" />
                                <label class="w-checkbox checkbox-field" for="leadid_tcpa_disclosure"><input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" class="w-checkbox-input" required><span class="checkbox-label w-form-label" for="checkbox">I have read and agree to the website refund policy and <a href="https://grow.sylo.ai/tos/" target="_blank"><span class="text-span-12">terms and conditions</span></a></span></label>
                            </div>
                            <button type="submit" class="submit-button-3 w-button" :disabled="submitted">
                                <span v-if="submitted">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="15px" height="15px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                        <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.53475935828877s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                        </circle>
                                    </svg>
                                </span>
                                <span v-else>{{buttonText}}</span>
                            </button>
                            <div class="w-form-fail" style="display: block;margin-top:-15px" v-if="frmerr">
                                <div>{{formerrmsg}}</div>
                            </div>
                            <div class="div-block-270"><img src="@/assets/images/paymentsecure.422c0e1.png" loading="lazy" width="130" alt=""></div>
                        </form>

                    </div>
                </div>
            </div>
            <div class="div-block-267">
                <div class="summary-desk">
                    <div class="text-block-82">Your Order</div>
                    <div class="div-block-269 _1">
                        <div class="text-block-87" v-if="trialDays>0">Sylo </div>
                        <div class="text-block-87" v-else>Sylo Identify AI</div>
                    </div>
                    <div class="div-block-269 _2">
                        <div class="text-block-88 _2">Total Due Now</div>
                        <div class="text-block-88 _2" v-if="trialDays==7">$0</div>
                        <div class="text-block-88 _2" v-else>${{formatPrice(planPrice)}}</div>
                    </div>
                    <div class="div-block-269 _2">
                        <div>
                            <div class="text-block-88 _2 after"><strong>Total Due After Trial</strong></div>
                        </div>
                        <div class="div-block-958">
                            <div class="text-block-88 _2 after"><strong>${{this.planPrice}}/mo</strong></div>
                            <div class="text-block-139" style="line-height: 20px;">*plus <strong>$.25</strong> per match</div>
                        </div>
                    </div>
                   
                    <div class="text-block-90" >Monthly Access. <span class="text-span-13">Cancel at Anytime. </span></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="wf-section">
    <div class="div-block-79"><img src="@/assets/images/Sylo-logo-BLUE.svg" loading="lazy" width="75" alt="" class="image-9">
        <div class="div-block-34">
            <div class="div-block-77">
                <div class="div-block-76">
                    <div class="text-block-21">
                        <a href="https://grow.sylo.ai/privacy/" target="_blank" class="link-5">Privacy Policy</a>
                    </div>
                </div>
                <div class="div-block-76">
                    <div class="text-block-21">
                        <a href="https://grow.sylo.ai/tos/" target="_blank" class="link-5">Terms of Site</a>
                    </div>
                </div>
            </div>
            <div class="div-block-74">
                <div class="text-block-23">Copyright {{getYear()}} Sylo</div>
                <div class="text-block-23">All Rights Reserved</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
var stripe = window.Stripe("pk_live_51IlDWLJxzgcaS8XZVoPodyfJ5LcHJxsbbFLzD6u2bKszu7LcZka1z7FXcq9LU65Ns6zoRzjj5NNzvjfyUqnylFPk00uF1amp2x");
var elements = stripe.elements(),
    card = undefined;

export default {
    components: {

    },
    data() {
        return {
            plan: 1,
            formerrmsg: '',
            planPrice: "",
            userData: [],
            couponId: null,
            coupon: '',
            couponErr: false,
            heading: "Start your 7 Day Trial",
            buttonText: "Start Your 7 Day Trial",
            trialDays: 7,
            frmerr: false,
            submitted: false,
            baseURL: "https://pages.tycoonmach.net/api",
            trustUrl: '',
            leadIdJornaya: '',
            pro: 'scale',
            val: 200,
        }
    },
    mounted() {
        this.setUpStripe();
    },
    methods: {
        getYear(){
            const d = new Date();
            return  d.getFullYear();
        },
        getFormValues() {
            let vm = this;
            this.frmerr = false;
            vm.submitted = true;
            this.trustUrl = document.getElementById("xxTrustedFormCertUrl_0").value;
            this.leadIdJornaya = document.getElementById("leadid_token").value;

            if (vm.password !== vm.confirm_password) {
                this.submitted = false;
                this.frmerr = true;
                this.formerrmsg = "Password doesn't match";
                return false;
            }

            stripe.createPaymentMethod({
                    type: 'card',
                    card: card,
                    billing_details: {
                        name: vm.userData.first_name + " " + vm.userData.last_name,
                        email: vm.userData.email,
                    },
                })
                .then(function (result) {
                    if (result.error) {
                        vm.frmerr = true;
                        vm.formerrmsg = result.error.message;
                        vm.submitted = false;
                        return false;
                    } else {
                        vm.registerUser(result.paymentMethod);
                    }
                });
        },
        registerUser(token) {
            this.submitted = true;
            window.axios.post(this.baseURL + '/register/user', {
                userdata: this.userData,
                trialdays: 7,
                token: token.id,
                plan: "price_1NpDijJxzgcaS8XZhQdIIb3V",
                coupon_id: this.couponId,
                user_type: this.user_type,
                ip_address: localStorage.getItem('ip'),
                journaya: this.journaya,
                trust: this.trustUrl
            }).then((response) => {
                if (response.data.status == 'success') {
                    window.location.href = "https://sylo.ai/thankyou/?name=" + this.userData.first_name + "&email=" + this.userData.email + "&utmid=" + response.data.id + "&tid=" + response.data.tid + "&pro=" + this.pro + "&val=" + this.val;
                } else {
                    this.submitted = false;
                    this.formerrmsg = response.data.message;
                    this.frmerr = true;
                }
            }).catch((error) => {
                this.submitted = false;
                this.formerrmsg = "An unexpected error occurred";
                this.frmerr = true;
                console.log(error);
            });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        setItems() {
            if (this.plan == "price_1NpDijJxzgcaS8XZhQdIIb3V" || this.plan == "price_1MHOP4JxzgcaS8XZhyWapoCq") {
                this.heading = "Start your 7 Day Trial";
                this.buttonText = "Start Your 7 Day Trial";
                this.trialDays = 7;
                this.user_type = 0;
            } else {
                this.heading = "Start your 7 Day Trial";
                this.buttonText = "Get Started";
                this.trialDays = 7;
                this.user_type = 2;
                this.pro = 'identify';
                this.val = 100;
            }
        },
        validateCoupon(event) {
            event.preventDefault();
            this.couponErr = false;
            if (this.couponId != null) {
                return false;
            }
            window.axios.post(this.baseURL + '/coupon/validate', {
                coupon: this.coupon
            }).then((response) => {
                if (response.data.status == 'success') {
                    this.couponId = response.data.coupon_id;
                    if (response.data.type == 'amount') {
                        this.planPrice = this.planPrice - response.data.discount;
                    } else {
                        this.planPrice = this.planPrice - ((this.planPrice * response.data.discount) / 100);
                    }
                } else {
                    this.couponErr = true;
                    this.couponId = null;
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        setUpStripe() {
            let vm = this;
            try {
                card = elements.create('card', {
                    style: {
                        base: {
                            iconColor: '#c4f0ff',
                            backgroundColor: '#ffffff',
                            fontSmoothing: 'antialiased',
                            ':-webkit-autofill': {
                                color: '#fce883',
                            },
                            '::placeholder': {
                                color: 'black',
                            },
                        },
                        invalid: {
                            iconColor: '#FFC7EE',
                            color: 'red',
                        },
                    },
                });
                card.mount(vm.$refs.card);
            } catch (error) {
                console.log(error)
            }
        }
    },
    created() {
        let ud = localStorage.getItem("user");
        if (ud == undefined || ud == null) {
            this.$router.push({
                path: "/"
            });
        } else {
            this.userData = JSON.parse(localStorage.getItem("user"));
            this.userData.phone = "";
            this.userData.password = "";
            this.userData.confirm_password = "";
            let v = 1;
            if (v == undefined || v == null || v == 0) {
                this.$router.push({
                    path: "/verify"
                });
            } else {
                this.planPrice = 195;
                this.plan = "price_1NpDijJxzgcaS8XZhQdIIb3V";
                /*let p = localStorage.getItem("plan");
                if (p == undefined || p == null) {
                    this.$router.push({
                        path: "/plan"
                    });
                } else {
                    this.planPrice = parseInt(localStorage.getItem("planPrice"));
                    this.plan = p;
                }*/
            }
        }
        this.setItems();

        try {
            window.ttq.track('InitiateCheckout');
        } catch (error) {
            console.log(error);
        }

        try {
            window.fbq.track('InitiateCheckout');
        } catch (error) {
            console.log(error);
        }
    }
}
</script>

<style>
.couponerror {
    border: solid red 2px;
}
</style>
