<template>
<div>
    <div class="div-block-826">
      <div class="div-block-827">
        <a href="#" class="w-inline-block"><img src="@/assets/images/Sylo-logo-BLUE.svg" loading="lazy" alt="" width="70"></a>
      </div>
    </div>
    <div class="div-block-828">
      <div class="text-block-59">Try it FREE for 7 days</div>
      <div class="text-block-60"><strong>Built for Enterprise. Priced for Everyone.</strong><br></div>
      <div class="div-block-830">
        <div class="price-column-1">
          <div class="div-block-831 _2 new platform">
            <div class="new-pricing-div">
              <div class="price-box-1">
                <div class="div-block-839">
                  <div class="text-block-141"><span class="text-span-24">Platform Cost</span></div>
                  <div class="div-block-834">
                    <div class="text-block-65">$195</div>
                    <div class="text-block-66"><span class="text-span-9">/mo</span><span class="text-span-10"></span></div>
                  </div>
                  <p class="paragraph-8">Monthly access to our entire suite of re-targeting, identification and engagement tools.</p>
                  <div class="div-block-835 b">
                    <div class="text-block-68">100,000 words generated</div>
                  </div>
                  <div class="div-block-836 b">
                    <div class="div-block-837"></div>
                  </div>
                  <div class="div-block-838 b">
                    <div class="text-block-70">50,000</div>
                    <div class="text-block-70">700,000+</div>
                  </div>
                  <div class="engage-div">
                    <div class="div-block-841"><img src="@/assets/images/icon_Check_1.svg" loading="lazy" width="20" alt="">
                      <div class="text-block-71 und"><strong>Multi-Channel </strong>Sequence Builder</div>
                    </div>
                    <div class="div-block-841"><img src="@/assets/images/icon_Check_1.svg" loading="lazy" width="20" alt="">
                      <div class="text-block-71 und"><strong>Manage Inbound</strong> Messages</div>
                    </div>
                    <div class="div-block-841"><img src="@/assets/images/icon_Check_1.svg" loading="lazy" width="20" alt="">
                      <div class="text-block-71 und"><strong>Voice / IVR</strong> Automation Builder<strong><br></strong></div>
                    </div>
                    <div class="div-block-841"><img src="@/assets/images/icon_Check_1.svg" loading="lazy" width="20" alt="">
                      <div class="text-block-71 und"><strong>OpenAI</strong> Sales Assistant<strong><br></strong></div>
                    </div>
                    <div class="div-block-841"><img src="@/assets/images/icon_Check_1.svg" loading="lazy" width="20" alt="">
                      <div class="text-block-71 und"><strong>Lead Management</strong> Dashboard<strong><br></strong></div>
                    </div>
                    <div class="div-block-841"><img src="@/assets/images/icon_Check_1.svg" loading="lazy" width="20" alt="">
                      <div class="text-block-71 und"><strong>Conversion </strong>Tracking <strong><br></strong></div>
                    </div>
                    <div class="div-block-841"><img src="@/assets/images/icon_Check_1.svg" loading="lazy" width="20" alt="">
                      <div class="text-block-71 und"><strong>Unlimited</strong> Integrations<strong><br></strong></div>
                    </div>
                    <div class="div-block-841"><img src="@/assets/images/icon_Check_1.svg" loading="lazy" width="20" alt="">
                      <div class="text-block-71 und"><strong>250 Identity Matches </strong>Included<strong><br></strong></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="div-block-842"></div>
          </div>
        </div>
        <div class="plus-column"><img src="@/assets/images/icon_plus.svg" loading="lazy" alt="" class="image-162"></div>
        <div class="div-block-831 _2 new secondary">
          <div class="new-pricing-div">
            <div class="price-box-1">
              <div class="div-block-839">
                <div class="text-block-141"><span class="text-span-24 pink">Identity Match Cost</span></div>
                <div class="div-block-834">
                  <div class="text-block-65">$.25</div>
                  <div class="text-block-66"><span class="text-span-9">/match</span> </div>
                </div>
                <p class="paragraph-8">You pay only when we identify a a new, unknown visitor.</p>
                <div class="div-block-835 b">
                  <div class="text-block-68">100,000 words generated</div>
                </div>
                <div class="div-block-836 b">
                  <div class="div-block-837"></div>
                </div>
                <div class="div-block-838 b">
                  <div class="text-block-70">50,000</div>
                  <div class="text-block-70">700,000+</div>
                </div>
              </div>
              <div class="div-block-955">
                <div class="div-block-956 _1">
                  <div class="div-block-957 top">
                    <div class="text-block-138">Volume</div>
                  </div>
                  <div class="div-block-957">
                    <div class="text-block-142">0-49,999</div>
                  </div>
                  <div class="div-block-957">
                    <div class="text-block-142">50,000-99,999</div>
                  </div>
                  <div class="div-block-957">
                    <div class="text-block-142">100,000-499,000</div>
                  </div>
                  <div class="div-block-957 bottom">
                    <div class="text-block-142">500,000+</div>
                  </div>
                </div>
                <div class="div-block-956 _2">
                  <div class="div-block-957 top">
                    <div class="text-block-138">Price Per Match</div>
                  </div>
                  <div class="div-block-957">
                    <div class="text-block-142">$0.25</div>
                  </div>
                  <div class="div-block-957">
                    <div class="text-block-142">$0.20</div>
                  </div>
                  <div class="div-block-957">
                    <div class="text-block-142">$0.15</div>
                  </div>
                  <div class="div-block-957 bottom">
                    <div class="text-block-142">$0.10</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="div-block-842"></div>
        </div>
      </div>
      <div class="div-block-844">
        <a href="/" class="button-14 boss outside w-button">Start 7-Day Trial &gt;&gt;</a>
      </div>
      <div class="div-block-844">
        <div class="text-block-74">Frequently asked questions</div>
        <div class="text-block-75">Can’t find the answer you’re looking for? Reach out to our <a href="https://sylo.crisp.help/en-us/" target="_blank" class="link-8"><strong class="bold-text">customer support </strong></a>team.<br></div>
        <p class="paragraph-9"><strong>Is it legal to send emails to people who have not opted in?<br>Yes. </strong>As long as you have formatted your emails correctly with your company information and an easy way to opt out. This is not legal advice (our lawyers make us put this), but you can easily search on the internet for reference. <a href="https://mailtrap.io/blog/can-spam-cold-emails/" target="_blank">Here is a good article explaining it.<br>‍</a><strong><br>‍</strong><strong>What if I do not run paid social ads?<br></strong>You can<strong> connect your own Twilio and SMTP server account </strong>and use Sylo to automate all your messaging at a very low cost. We charge additional fees (which we pay to OpenAI) to use GPT4 to handle your inbound messages at .01-.03 cents per response. <br>‍<br>‍<strong>How do I get more credits?<br>‍</strong>Once you balance falls below a certain amount you will be automatically billed a deposit amount you set. This will ensure your account keeps running without interruption. You can also buy Identity credits in bulk at discounted rates in our marketplace or speak with a account representative.<br><br><strong>Do I need a developer to install Sylo?<br>‍</strong>No. Setting up Sylo requires zero code other than being able to place a pixel code on your website. Most landing page builder and websites have easy instructions on how to do this.<br></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {
            planType: 2,
            planPrice: "2,655",
            planPriceAI: "1,341",
            planPriceExtend: "8,055",
            selPlan: "",
            userData: [],
        }
    },
    methods: {
        setPlan(event, val) {
            event.preventDefault();
            let redrct = 1;
            if (val == 1) {
                if (this.planType == 1) {
                    localStorage.setItem("plan", "price_1MHONcJxzgcaS8XZ3e7CfLhy");
                    localStorage.setItem("planPrice", "1341");
                } else {
                    localStorage.setItem("plan", "price_1MHONcJxzgcaS8XZIeRLEb1g");
                    localStorage.setItem("planPrice", "149");
                }
            } else if (val == 2) {
                if (this.planType == 1) {
                    localStorage.setItem("plan" , "price_1MHOP4JxzgcaS8XZhyWapoCq");
                    localStorage.setItem("planPrice", "2655");
                } else {
                    localStorage.setItem("plan" , "price_1MHOP4JxzgcaS8XZo8vFCqy8");
                    localStorage.setItem("planPrice", "295");
                }
            } else {
                window.$crisp.push(['do', 'chat:open']);
                redrct =0;
            }
           
            if (redrct == 1) {
                window.location.href="/signup";
            }
        },
        setPricing() {
            if (this.planType == 1) {
                this.planPrice = "2,655";
                this.planPriceAI = "1,341";
                this.planPriceExtend = "8,055";
            } else {
                this.planPrice = 295;
                this.planPriceAI = 149;
                this.planPriceExtend = 895;
            }
        },

    },
    created() {
        let ud = localStorage.getItem("user");
        if (ud == undefined || ud == null) {
            this.$router.push({
                path: "/"
            });
        } else {
            this.userData = JSON.parse(localStorage.getItem("user"));
        }
        this.setPricing();
        try{
            window.ttq.track('CompleteRegistration');
        }catch(error){
            console.log(error);
        }

        try{
            window.fbq.track('CompleteRegistration');
        }catch(error){
            console.log(error);
        }
    }
}
</script>

<style>

</style>
