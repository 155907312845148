import { createWebHistory, createRouter } from "vue-router";


import Home from "@/components/homePage.vue";
//import Verify from "@/components/verifyEmail.vue";
import syloPricing from "@/components/syloPricing.vue";
//import syloPriceTwo from "@/components/syloPriceTwo.vue";
import syloCheckout from "@/components/syloCheckout.vue";
//import identifyAi from "@/components/identifyAi.vue";
//import checkoutTwo from "@/components/checkoutTwo.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  /* {
    path: "/verify",
    name: "Verify",
    component: Verify,
  },
 {
    path: "/plan",
    name: "syloPricing",
    component: syloPricing,
  },*/
  {
    path: "/pricing",
    name: "syloPriceTwo2",
    component: syloPricing,
  },
  {
    path: "/plan",
    name: "syloPriceTwo",
    component: syloPricing,
  },/*
  {
    path: "/account",
    name: "checkoutTwo",
    component: checkoutTwo,
  },
  {
    path: "/identify",
    name: "identifyAi",
    component: identifyAi,
  },*/
  {
    path: "/signup",
    name: "syloCheckout",
    component: syloCheckout,
  }
  
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;