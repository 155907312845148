<template>
<div class="section-8 wf-section">
    <div class="div-block-808">
        <div class="div-block-809">
            <div><img src="@/assets/images/Sylo-logo-BLUE.svg" loading="lazy" width="70" alt="" class="image-139">
                <div class="text-block-47">Create your account</div>
                <div class="text-block-48">You&#x27;ll be up &amp; running in 2 minutes</div>
                <!--<a href="#" class="link-block-3 w-inline-block">
                    <div class="div-block-811" style="background-color:#fff" id="buttonDiv">

                    </div>
                </a>
                <div class="div-block-813">
                    <div class="div-block-814"></div>
                    <div class="text-block-50">Or register with your email</div>
                    <div class="div-block-814"></div>
                </div>-->
                <div>
                    <div class="w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" @submit.prevent="handleSignIn">
                            <div class="div-block-815">
                                <div class="div-block-816">
                                    <div class="text-block-51">First name</div><input type="text" class="text-field-2 w-input" maxlength="256" name="field" data-name="Field" v-model="first_name" placeholder="First Name" id="field" required>
                                </div>
                                <div class="div-block-816">
                                    <div class="text-block-51">Last name</div><input type="text" class="text-field-2 w-input" maxlength="256" name="field-2" data-name="Field 2" v-model="last_name" placeholder="Last Name" id="field-2" required>
                                </div>
                                <input type="hidden" name="xxTrustedFormCertUrl" id="xxTrustedFormCertUrl_0" value="">
                                <input id="leadid_token" name="universal_leadid" type="hidden" value="" />
                            </div><label for="name" class="field-label-2">Email address</label><input type="email" class="text-field-3 w-input" maxlength="256" name="name" data-name="Name" v-model="email" placeholder="Email" required>
                            <button type="submit" :disabled="submitted" class="link-block-4 w-inline-block" style="background-color:transparent;">
                                <div class="div-block-817" v-if="submitted">
                                   <div class="text-block-52">Please wait...</div>                                   
                                </div>
                                <div class="div-block-817" v-else>
                                    <div class="text-block-52">Continue</div><img src="@/assets/images/icon_arrow-forward.svg" loading="lazy" width="15" alt="">
                                </div>
                            </button>
                            <div class="text-block-54">Already have an account? <a href="https://app.sylo.ai" class="link-6">Login</a>
                            </div>
                        </form>
                        <div class="w-form-fail" style="display:block" v-if="error">
                            <div>A user with this email is already present. Please login into your account</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="div-block-810">
            <div class="text-block-53">Make Sylo your competitive advantage.</div>
            <div class="div-block-818">
            <img src="@/assets/images/window-world.png" loading="lazy" width="80" alt="">
            <img src="@/assets/images/Sunrun_logo_PNG4.png" loading="lazy" width="80" alt="">
            <img src="@/assets/images/BMW_logo_PNG2.png" loading="lazy" width="50" alt="">
            <img src="@/assets/images/Hyatt_logo_PNG1.png" loading="lazy" width="80" alt="">
            <img src="@/assets/images/RateGenie_white.png" loading="lazy" width="80" alt="">
            </div>
            <div class="div-block-819">
            <img src="@/assets/images/Coldwell_Banker_logo_PNG3.png" loading="lazy" width="110" alt="">
            <img src="@/assets/images/Mutual-of-Omaha-logo.png" loading="lazy" width="150" alt="">
            <img src="@/assets/images/Luxury_Escapes_Logo.png" loading="lazy" width="120" alt="">
            <img src="@/assets/images/Allstate_logo_PNG10.png" loading="lazy" width="100" alt="">
            </div>
        </div>
    </div>
</div>
</template>

<script>
var Buffer = require('buffer/').Buffer
export default {
    name: 'App',
    components: {

    },
    data() {
        return {
            first_name: '',
            last_name: '',
            id:0,
            email: '',
            error: false,
            submitted:false,
            baseURL:"https://pages.tycoonmach.net/api",
            trustUrl:'',
            leadIdJornaya:''
        }
    },
    mounted() {
        //this.makeButton();
    },
    methods: {
        makeButton() {
            let self = this;
            let google = window.google;
            google.accounts.id.initialize({
                client_id: "533659261408-9lhbkkj6vdblet6qmnla99holo0qqefm.apps.googleusercontent.com",
                callback: self.handleCredentialResponse
            });
            google.accounts.id.renderButton(
                document.getElementById("buttonDiv"), {
                    theme: "filled_blue",
                    size: "large",
                    text: "continue_with"
                } // customization attributes
            );
            google.accounts.id.prompt(); // also display the One Tap dialog

        },
        handleCredentialResponse(response) {
            const responsePayload = this.decodeJwtResponse(response.credential);
            this.trustUrl = document.getElementById("xxTrustedFormCertUrl_0").value;
            this.leadIdJornaya = document.getElementById("leadid_token").value;

            let userData = {
                "id" : this.id,
                "first_name": responsePayload.given_name,
                "last_name": responsePayload.family_name,
                "email": responsePayload.email,
                "image": responsePayload.picture,
                "utype": 1,
                "journaya": this.journaya,
                "trust" : this.trustUrl
            };
            this.createUser(userData);
        },
        handleSignIn() {
            this.trustUrl = document.getElementById("xxTrustedFormCertUrl_0").value;
            this.leadIdJornaya = document.getElementById("leadid_token").value;
            let userData = {
                "id" : this.id,
                "first_name": this.first_name,
                "last_name": this.last_name,
                "email": this.email,
                "image": "/no-image.png",
                "utype": 2,
                "journaya": this.journaya,
                "trust" : this.trustUrl
            }
            this.createUser(userData);
        },
        createUser(data) {
            this.error = false;
            this.submitted=true;
            window.axios.post(this.baseURL+"/sign-up", data).then(response => {
                this.submitted=false;
                data.id = response.data.id;
                localStorage.setItem("user", JSON.stringify(data));
                if (response.data.status == "error" && response.data.active == 1) {
                    this.error = true;
                } else {
                    if (data.utype == 1) {
                        localStorage.setItem("verified", 1);
                        window.location.href="/signup";
                    } else {
                        localStorage.setItem("verified", 1);
                        window.location.href="/signup";
                    }
                }
            });
        },
        decodeJwtResponse(token) {
            var base64Payload = token.split('.')[1];
            var payload = Buffer.from(base64Payload, 'base64');
            return JSON.parse(payload.toString());
        }

    },
    created() {

    }
}
</script>

<style>

</style>
